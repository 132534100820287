.Home-jumbotron {
    margin-top: 30px;
    padding-top: 60px;

    /* Option 1 - Gradient background */
    background: #7F7FD5;
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);

    /* Option 2 - Image background */
    /*background-image: url(https://images.unsplash.com/photo-1552152974-19b9caf99137?fit=crop&w=1350&q=80);*/
    /*background-image: url(https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736881_1280.jpg);*/
    /*color: white;*/

    height: 300px;
}

.Home-jumbotron-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.Home-jumbotron-subtitle {
    /* color: #5f6368; */
    font-size: 16pt;
    font-weight: normal;
    /* font-style: italic; */
}

.Home-jumbotron-buttons-row {
    margin-top: 40px;
    flex-direction: row;
    align-items: center;
}

.Home-jumbotron-buttons-col {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
}

.Home-jumbotron-button {
    width: 220px;
    margin: 6px;
    /*text-transform: none;*/
}

.Home-product-container {
    /*text-align: center;*/
    margin-top: 30px;
}


/* Responsive layout for mobile devices */
@media screen and (max-width: 600px) {
    .Home-jumbotron {
        margin-top: 30px;
        /*background: #f8f9fa;*/
        /*color: #212529;*/
    }
}
