/* make the customizations */
// Colors
$blue:    #007bff !default; // primary
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default; // danger
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default; // warning
$green:   #28a745 !default; // success
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default; // info

// Grays
$white:    #fff !default;
$gray-100: #f8f9fa !default; // light
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default; // secondary
$gray-700: #495057 !default;
$gray-800: #343a40 !default; // dark
$gray-900: #212529 !default;
$black:    #000 !default;

// Custom colors
$color-primary:     $blue;
$color-secondary:   #868e96;
$color-light:       #f8f9fa;  //$white;

$theme-colors: (
        "primary": $color-primary,
        //"secondary": $color-secondary
        //"info": tomato,
        //"danger": teal,
        //"light": $color-light
);

/* import bootstrap to set changes */
@import "../node_modules/bootstrap/scss/bootstrap";