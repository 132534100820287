@import "custom";

body {
  margin: 0;
  padding: 0;
  //font-family: sans-serif;
  font-family: HelveticaNeue, "Helvetica Neue", "Apple SD Gothic Neo", Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

span {
  &.dangerText {
    color: #dc3545;
  }

  &.infoText {
    color: #17a2b8;
    font-weight: bold;
  }
}

.header {
  text-align: center;
}

a.disabled {
  pointer-events: none;
  color: gray;
}

.custom-btn-circle {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50% !important;
  padding: 6px 0 !important;
  text-align: center !important;
  font-size: 12px !important;
  line-height: 1.42857 !important;
}

.custom-btn-rounded {
  width: 200px;
  text-align: center !important;
  border-radius: 15px !important;

  display: flex;
  flex-direction: column;
  justify-content: center;   /* horizontal alignment */
  align-items: center;       /* vertical alignment */
}

.custom-btn-rounded-lg {
  height: 48px;
  //text-transform: none;
  border-radius: 24px !important;

  display: flex;
  flex-direction: column;
  justify-content: center;   /* horizontal alignment */
  align-items: center;       /* vertical alignment */
}

.dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
}

/* Override slick carousel styles --> Start */
.slick-prev:before, .slick-next:before {
  color: #343a40;
}
.slick-track{
  margin-left: 0; /* left align */
}
/* Override slick carousel styles <-- End */


/* Override Bootstrap card styles --> Start */
.card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
  cursor: pointer;
}
.card:hover {
  /*transform: scale(1.05);*/
  transform: scale(1.01);
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}
/* Override Bootstrap card styles <-- End */


/* Override Bootstrap list group styles --> Start */
.list-group-item:hover {
  background-color: #f5f5f5;
}
/* Override Bootstrap list group styles <-- End */


.App-footer {
  margin-top: 40px;
  text-align: center
}